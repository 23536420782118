import React from "react"
import ImageSection from "../ImageSection"

import styles from "./ResponsibleLending.module.css"

const ResponsibleLendingView = () => {
  return (
    <div>
      <ImageSection />
      <section className={styles.section}>
        <h1>RESPONSIBLE LENDING</h1>
        <h3>Responsible Lending Practices</h3>
        <p>
          We are not a loan providing service, nor do we represent or endorse
          any lender. But we are working hard to provide you with access to
          reliable lenders and connect you with lenders who can offer you loan
          products according to the information you submit on our online form.
          We put maximum efforts to ensure that all the lenders in our network
          are legitimate loan providers adhering to federal, state and local
          laws, fair and responsible lending practices and the best practices in
          the industry. We understand that short-term loan industry can be
          puzzling, as it is regulated in many states heavily and differently.
          For this reason, we provide you with the necessary information and
          educational material on the industry to answer your questions
          regarding short-term loans in the USA. In this section we’ll provide
          the basic information regarding the major laws and acts that secure
          and protect consumers from unfair practices. We encourage you to go
          through this information and read the basics on How It Works, FAQ and
          Rates and Fees pages to understand your credit options better and make
          only smart and reasonable credit decisions.
        </p>
        <h3>Fair Debt Collection Practices Act</h3>
        <p>
          Fair Debt Collection Practices Act We strictly insist that our lenders
          follow the guidelines put in place by the Fair Debt Collection
          Practices Act. We are not a lender, so we will never make any attempt
          to collect a debt from you. However, our lenders have the right to use
          all lawful means available to them to collect your debt if your loan
          is unpaid. They must follow the guidelines of the Fair Debt Collection
          Practices Act when doing so. These are some of major actions deemed as
          violations of the Act:
        </p>
        <p>
          ● Using any harmful, oppressive, foul or harassing language or tactics
          in order to collect debt;
        </p>
        <p>
          ● Attempting to contact debtors before 9 a.m. or after 8 p.m. in their
          local time zones;
        </p>
        <p>
          ● Using deception, falsifying information or misrepresenting a company
          in order to collect a debt;
        </p>
        <p>
          ● Threaten criminal charges or any legal action when such legal action
          is not warranted.
        </p>
        <p>
          Any lender within our network found to be in violation of these
          guidelines will be immediately removed from our network and reported
          to law enforcement.
        </p>
        <h3>Fair Lending Act</h3>
        <p>
          The purpose of the Fair Lending Act is to ensure that all consumers
          have equal access to credit despite their age, race, creed, gender,
          religion or other non-financial factor. The Act ensures that consumers
          are not discriminated against and that they have access to the loan
          and other forms of consumer credit as long as these consumers can meet
          the legal and financial guidelines associated with obtaining such
          credit. If you feel that you are a victim of discrimination, please
          contact the Equal Opportunity and Fair Lending Office of your local
          Consumer Financial Protection Bureau.
        </p>
        <h3>Truth in Lending Act</h3>
        <p>
          The Truth in Lending Act was established to ensure that consumers have
          all of the information about an offer of credit available to them in
          writing before they take any responsibility and e-sign any contract or
          decide to extend any credit. Lenders are required to disclose
          information about the rates, fees, interest and terms associated with
          loans before they provide any credit to any consumer. Each state has
          its individual regulations regarding short term lending, and lenders
          are required to ensure that all offers are in accordance with these
          regulations.
        </p>
        <h3>State Regulations </h3>
        <p>
          Many states have implemented their own regulations for the short term
          lending industry in an effort to protect consumers. Some of the things
          individual states regulate include the maximum loan amounts, fees and
          interest, the number of rollovers that are available and more. The
          general information about the regulations in specific states can be
          found in our Rates and Fees section of this website. You can find the
          links to the governmental websites with detailed explanation on short
          term loan regulations and up-to-date rate limits in different states.
        </p>
      </section>
    </div>
  )
}

export default ResponsibleLendingView
