import React from "react"

import Layout from "../components/Layout"
import ResponsibleLending from "../components/ResponsibleLending"
import SEO from "../components/seo"

const ResponsibleLendingPage = ({ location, history }) => (
  <Layout location={location} history={history}>
    <SEO title="Responsible Lending" />
    <ResponsibleLending />
  </Layout>
)

export default ResponsibleLendingPage
